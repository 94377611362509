<!-- @Author: Yu_Bo -->
<template>
  <div class='consumption_details zc_dialog_box'>
    <el-dialog :visible.sync="dialogVisible" width="962px" :close-on-click-modal='false'>
      <div slot="title">
        <div class="name">消耗详情</div>
      </div>
      <!--  -->
      <div class="details_info scroll_bar_x">
        <el-table :data="tableData" :header-cell-style="headerCellStyle" stripe style="width: 100%">
          <el-table-column class-name="one_class" prop="" label="场景" width="100" align="center">
            <template slot-scope="scope">
              <div>{{ scope.$index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="model" :label="head" align="center"> </el-table-column>
          <el-table-column prop="type" label="消费类型" align="center"> </el-table-column>
          <el-table-column prop="" label="消耗" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.amount }}{{ scope.row.unit }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="剩余" align="center">
            <template slot-scope="scope">
              <div>{{ scope.row.balance }}{{ scope.row.unit }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      headerCellStyle: {
        background: '#F0F2F5',
        color: '#333',
        fontWeight: 500,
        fontSize: '14px'
      },
      head: '',
    }
  },
  computed: {
    // timesChange() {
    //   return (val) => {
    //     return `${this.$utils.formatSeconds(val, 3).h ? this.$utils.formatSeconds(val, 3).h + '小时' : ''}${this.$utils.formatSeconds(val, 3).m ? this.$utils.formatSeconds(val, 3).m + '分钟' : ''}${this.$utils.formatSeconds(val, 3).s}秒`
    //   }
    // }
  },
  watch: {},
  created() { },
  mounted() { },
  methods: {
    // 打开弹框
    openDialogBtn(info) {
      this.dialogVisible = true
      this.tableData = info.consumeOtherDetail
      this.head = info.head
    },
  },
}
</script>

<style lang='scss' scoped>
.consumption_details {
  .details_info {
    width: 100%;
    max-height: 500px;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;
    overflow-y: auto;
  }
}

::v-deep .one_class {
  border-right: 1px solid #EBEEF5;
}
</style>
